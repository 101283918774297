import React, { Component } from 'react';
import { Container, Row, Col, Image, Card } from 'react-bootstrap';
import photo from './photo.jpg';
import emailIcon from './email.png';
import vcardIcon from './vcard.png';

import './App.css';

class App extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <Container>
        <Row>
          <Col md={2} xs={12} style={{margin: 10}}>
            <Image src={photo} rounded />
            <div className="horizontal-margin">
              <a href="https://www.linkedin.com/in/jmgoncalves"><img className="ico" src="http://www.linkedin.com/favicon.ico" style={{height: 16}}/></a>
              <a href="https://github.com/jmgoncalves"><img className="ico" src="https://github.com/favicon.ico" style={{height: 16}}/></a>
              <a href="mailto:me@jmgoncalv.es"><img className="ico" src={emailIcon} style={{height: 16}}/></a>
              <a href="/jmgoncalves.vcf"><img className="ico" src={vcardIcon} style={{height: 16}}/></a>
            </div>
          </Col>
          <Col>
            <Card border="light">
              <Card.Body>
                <Card.Title>jmgoncalves</Card.Title>
                <Card.Subtitle className="mb-2 text-muted">Equal parts mix of engineer, scientist and hacker</Card.Subtitle>
                <Card.Text>
                  Born in a small town in Portugal, took way too long to move to London.<br/>
                  Survived my PhD while thinking there had to be more impactful things I could be doing with all that time.<br/>
                  Thought the Gang of Four book was great but then discovered functional programming.<br/>
                  Often busy trying to escape management roles that inadvertedly got myself into.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default App;
